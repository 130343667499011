import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import React, { useState,useEffect } from 'react';
import { setRadius } from '../../../../state/features/roadProfileDataSlice';
import { useDispatch } from 'react-redux';
import './RadiusPopup.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../state/hooks';
import { setCurrentLanguage } from '../../../../state/features/languageSlice';




interface RadiusPopupProps {
    visible: boolean;
    onHide: () => void;
    onSubmit: (radius: number) => void;
}

export const RadiusPopup: React.FC<RadiusPopupProps> = ({ visible, onHide, onSubmit }) => {
    
    const [radius, setRadius] = useState<number | null>(null);
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const customize: any = useAppSelector((state: any) => state.customize.value)
    const dispatch = useDispatch();


    useEffect(() => {
        // if(logoff){
        //     window.location.reload();
        // } 
       
        localStorage.setItem('activeLanguage', +(window as any).innerWidth <= 640 ? 'eng' : localStorage.getItem('activeLanguage') || 'tr');
    
        if(+(window as any).innerWidth <= 640){
            i18n.changeLanguage("eng");
            dispatch(setCurrentLanguage("eng"))
        }
    
        // dispatch(setLogOff(false)); 
      }, [])


    const handleSubmit = () => {
        if (radius && radius * 100 > 70) {
            setError(t('TEXT.Maximum value should be 70 cm'));
            const input = document.querySelector('.p-inputnumber input');
            input?.classList.add('error');
            setTimeout(() => input?.classList.remove('error'), 500);
            return;
        }
        
        if (radius) {
            dispatch({ type: 'roadProfileData/setRadius', payload: radius });
            onSubmit(radius);
            setRadius(null);
            onHide();
        }
    };


    return (
        <Dialog
            header={t(('HEADER.Enter Profile Radius'))}
            visible={visible}
            onHide={onHide}
            footer={
                <div>
                    <Button 
                        label={t(('BUTTON.Submit'))}
                        onClick={handleSubmit} 
                        autoFocus 
                    />
                    <Button 
                        label={t(('BUTTON.Cancel'))}  
                        onClick={onHide} 
                        className="p-button-text" 
                    />
                </div>
            }
            modal={false}
            style={{ width: '300px' }}
        >
            <div className="flex flex-column gap-2">
                <InputNumber
                    id="radius"
                    value={radius ? radius * 100 : null}
                    onValueChange={(e) => {
                        setRadius(e.value ? e.value / 100 : null);
                        setError('');
                    }}
                    mode="decimal"
                    minFractionDigits={0}
                    maxFractionDigits={2}
                    placeholder={t(('TEXT.Enter radius'))}
                />
                {error && <div className="error-message">{error}</div>}
                <div className="helper-text">{t(('MESSAGE.Please enter a radius value between 0 and 70 cm'))}</div>
            </div>
        </Dialog>
    );
};


export default RadiusPopup;