import './Sidebar.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import { Tree } from 'primereact/tree';
import TreeNode from 'primereact/treenode';
import { LayerRestApi } from '../../../util/restapi/layer';
import { setExternalLayerExtent, setIsLayerVisible, setProjects, setSelectedLayer, setSelectedLayerGeoserverUrl, setStyles, setVisibleDomains, setWorkspace, setEnabledGroupAlias, setOfflines, setSelectedKeysforPano, setLayerNodes, setSelectedLayerTable, setSelectedLayerGeoserverUrlTable } from '../../../state/features/layerSlice';
import { Field, Layer, LayerGroup, Project } from '../../../util/model/project';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from 'primereact/contextmenu';
import { setButtonState, setExportButton, setTableButton } from '../../../state/features/buttonStateSlice';
import { FeatureRestApi } from '../../../util/restapi/features';
import { setFeatureExtent, setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { setExportLayerAlias, setExportLayerUrl, setExportLayerWorkspace, setShowExport } from '../../../state/features/exportSlice';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { transformExtent } from 'ol/proj';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import { setImportedFileVisibility } from '../../../state/features/importSlice';
import { setSidebarWidth } from '../../../state/features/styleControlSlice';
import { Button } from 'primereact/button';
import {get} from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
//@ts-ignore
import proj4List from 'proj4-list';



export const Sidebar = () => {
  const dispatch = useDispatch()
  const loadSelectionKeys = () => {
    const savedKeys = localStorage.getItem('selectedKeys');
    return savedKeys ? JSON.parse(savedKeys) : {};
  };
  const { t } = useTranslation()
  const toast = useRef<any>(null);
  const [nodes, setNodes] = useState<TreeNode[]>();
  const [clickedId, setClickedId] = useState<TreeNode[]>();
  const [wasNodesSet, setWasNotesSet] = useState<boolean>(false)
  const [isExpandedKeysDefaultFlag, setIsExpandedKeysDefaultFlag] = useState<boolean>(true);
  const [expandedKeysArrayFromLocal, setExpandedKeysArrayFromLocal] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>(loadSelectionKeys());
  const [expandedKeys, setExpandedKeys] = useState({});
  const [collapseButtonVis, setCollapseButtonVis] = useState<boolean>(false);
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>();
  const [contextMenuSelectedItem, setContextMenuSelectedItem] = useState<any>();
  const [styles, setUsedStyles] = useState<any>();
  const [findedService, setFindedService] = useState<any>({ type: '', url: 'workspace: ' });
  const services: any = useAppSelector(state => state.layer.services);
  const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
  const selectedLayer: any = useAppSelector((state: RootState) => state.layer.selectedLayer);
  const contextMenu: any = useRef(null);
  const visibleLayers: any = localStorage.getItem('sidebarLayersAreOpened')
  const parsedLayers = visibleLayers && visibleLayers.length > 0 ? JSON.parse(visibleLayers) : []
  const importedFile = useAppSelector(state => state.import.importedFile);
  const importLayerGroup: any = useAppSelector(
    (state) => state.import.importLayerGroup
  );
  const [selectedGroupAlias, setSelectedGroupAlias] = useState<any>(null);
  const offlines = useAppSelector(state => state.layer.offlines);
  const language = useAppSelector((state) => state.language.value);
  const sidebarRef = useRef<any>();
  const [sidebarKeyCount, setSidebarKeyCount] = useState<string>('key.1')
  const isMobileScreen: boolean = useAppSelector((state: any) => state.styleControl.isMobileScreen)
  const sidebarVisibility = useAppSelector(state => state.buttonState.sidebarButton);
  
  const documentHead: any = document.getElementsByTagName('head')[0];

/*   const [styleRefTmp, setStyleRef] = useState<any[]>([]);
  const [mapOfStyles, setMapOfStyleArray] = useState<any>(); */
  
  const styleRef :any[] = [];
  const mapOfStyleArray = new Map();

  let jj=0;
  useEffect(() => {
    if (wasNodesSet) {
      let arrayFromLocal = []
      const localString = localStorage.getItem('expandedKeysFromLocal')
      if (localString && localString != '') {
        arrayFromLocal = JSON.parse(localString)
      }
      setExpandedKeysArrayFromLocal(arrayFromLocal)
    }
  }, [wasNodesSet])
  useEffect(() => {
    localStorage.setItem('selectedKeys', JSON.stringify(selectedKeys));
  }, [selectedKeys]);
  useEffect(() => {
    if (wasNodesSet) {
      const arrayToProcess = []
      if (nodes) {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          const children = node.children
          for (let index = 0; index < expandedKeysArrayFromLocal.length; index++) {
            const node = expandedKeysArrayFromLocal[index];
            if (node.project_id && !node.layer_group_id && !node.layer_id && !node.layer_name) {
              arrayToProcess.push(node)
            }
          }
          if (children?.length) {
            for (let j = 0; j < children.length; j++) {
              const child = children[j];
              for (let index = 0; index < expandedKeysArrayFromLocal.length; index++) {
                const node = expandedKeysArrayFromLocal[index];
                if (node.project_id && node.layer_group_id && !node.layer_id && !node.layer_name) {
                  arrayToProcess.push(node)
                }
              }
              const grandChildren = child.children
              if (grandChildren?.length) {
                for (let k = 0; k < grandChildren.length; k++) {
                  const grandChild = grandChildren[k];
                  for (let index = 0; index < expandedKeysArrayFromLocal.length; index++) {
                    const node = expandedKeysArrayFromLocal[index];
                    if (node.project_id && node.layer_group_id && node.layer_id && node.layer_name) {
                      arrayToProcess.push(node)
                    }
                  }
                }
              }
            }
          }
        }

        let expandedKeysObject: any = {}
        for (let i = 0; i < arrayToProcess.length; i++) {
          const element = arrayToProcess[i];
          if (element.project_id && !element.layer_group_id && !element.layer_name) {
            for (let i = 0; i < nodes.length; i++) {
              const node = nodes[i] as any;
              if (element.project_id == node.id) {
                expandedKeysObject[node.key] = true
              }
            }
          }
          if (element.project_id && element.layer_group_id && !element.layer_name) {
            for (let i = 0; i < nodes.length; i++) {
              const node = nodes[i] as any;
              for (let j = 0; j < node.children.length; j++) {
                const child = node.children[j];
                if (element.project_id == child.project_id && element.layer_group_id == child.id) {
                  expandedKeysObject[child.key] = true
                }
              }
            }
          }
          if (element.project_id && element.layer_group_id && element.layer_name) {
            for (let i = 0; i < nodes.length; i++) {
              const node = nodes[i] as any;
              for (let j = 0; j < node.children.length; j++) {
                const child = node.children[j];

                for (let k = 0; k < child.children.length; k++) {
                  const grandChild = child.children[k];
                  if (element.project_id == child.project_id && element.layer_group_id == child.layer_group_id && element.layer_name == child.name) {
                    expandedKeysObject[grandChild.key] = true
                  }
                }
              }
            }
          }
        }
        setIsExpandedKeysDefaultFlag(false)
        setExpandedKeys(expandedKeysObject)
      }
    }

  }, [expandedKeysArrayFromLocal])

  useEffect(() => {
    if (!sidebarRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      dispatch(setSidebarWidth(sidebarRef.current.offsetWidth || 0))
    });
    resizeObserver.observe(sidebarRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (contextMenuSelectedItem) {
      const findedService = services.find((service: any) => { return service.id === contextMenuSelectedItem.service_id })
      if (findedService) {
        setFindedService({ type: findedService.type, url: findedService.url, workspace: findedService.workspace })
      }
    }
  }, [services, contextMenuSelectedItem])

  const contextMenuItems = [
    findedService.type !== 'EXTERNAL_LAYER' && findedService.type !== 'RASTER' ? {
      label: t('CONTEXT MENU.Go to the Layer'),
      command: (e: any) => {
        const url = services.find((service: any) => service.id === contextMenuSelectedItem.service_id)?.url
        dispatch(setSelectedLayer(contextMenuSelectedItem));
        dispatch(setSelectedLayerGeoserverUrl(url));
        getFeatures(url);
      }
    } : {
      label: t('CONTEXT MENU.Go to the Layer'),
      command: () => {
        if (findedService.type == 'EXTERNAL_LAYER') {
          goToExternalLayer(contextMenuSelectedItem);
        }
        else if (findedService.type == 'RASTER') {
          goToTheRasterLayer(contextMenuSelectedItem);
        }
      }
    },
    findedService.type !== 'EXTERNAL_LAYER' && findedService.type !== 'RASTER' ? {
      label: t('CONTEXT MENU.Open/Close Table'),
      command: () => {
        dispatch(setSelectedFeature([]))
        const url = services.find((service: any) => service.id === contextMenuSelectedItem.service_id)?.url
        const workspace = services.find((service: any) => service.id === contextMenuSelectedItem.service_id).workspace;
        // dispatch(setSelectedLayer(contextMenuSelectedItem));
        // console.log("contextMenuSelectedItem :",contextMenuSelectedItem);
        nodes?.map((node: any) => {
          if (node.children) {
            node.children.map((child: any) => {
              child.children.find((child2: any) => {
                if (child2.id === contextMenuSelectedItem.id) {
                  spanClicked(undefined, child2);
                }
              })
            })
          }
        })
      /*   dispatch(setSelectedLayerTable(contextMenuSelectedItem));
        dispatch(setSelectedLayerGeoserverUrl(url));
        dispatch(setWorkspace(workspace)); */
        dispatch(setTableButton(true))
      }
    } : {
      className: "hidden-context-menu",
    },
    findedService.type !== 'EXTERNAL_LAYER' && findedService.type !== 'RASTER' ?
      {
        label: t('CONTEXT MENU.Export'),
        command: () => {
          const clickedNodeId = contextMenuSelectedItem.id
          // const clickedNodeId = node.id;
          nodes?.map((node: any) => {
            if (node.children) {
              node.children.map((child: any) => {
                child.children.find((child2: any) => {
                  if (child2.id === clickedNodeId) {
                    if (child2) {
                      const url = services.find((service: any) => service.id === child2.service_id)?.url
                      const workspace = services.find((service: any) => service.id === child2.service_id).workspace;
                      const service = services.find((service: any) => service.id === child2.service_id)
                      const layerPerm = service?.layers.find((layer: any) => child2.id === layer.id)?.perm
                      // child2.perm = layerPerm
                      const cloneObject = { ...child2 }
                      cloneObject.perm = layerPerm
                      if (cloneObject.perm >= 3) {
                        dispatch(setSelectedLayerGeoserverUrl(url));
                        dispatch(setWorkspace(workspace));
                        dispatch(setSelectedLayer(cloneObject))

                        dispatch(setExportLayerAlias(cloneObject.alias))
                        dispatch(setExportLayerUrl(url))
                        dispatch(setExportLayerWorkspace(workspace))
                        dispatch(setButtonState(ButtonState.EXPORT))
                        dispatch(setExportButton(true))
                        dispatch(setShowExport(true))
                      }
                      else {
                        toast.current?.show({ severity: 'error', detail: t("MESSAGE.NotAllowedForThisOp"), life: 5000 });
                      }
                    }
                  }
                })
              })
            }
          })
        }
      } : {
        className: "hidden-context-menu",
      },
  ];

  const goToExternalLayer = async (selectedLayer: any) => {
    if (findedService.type === 'EXTERNAL_LAYER') {

      try {
        const xmlCapabilities =
          await axios.get(`${findedService.url}?service=wms&version=1.3.0&request=GetCapabilities`, { responseType: 'text' })

        const wmsCapabilitiesFormat: any = new WMSCapabilities();
        const jsonCapabilities = wmsCapabilitiesFormat.read(xmlCapabilities.data)

        if (jsonCapabilities && jsonCapabilities.Capability
          && jsonCapabilities.Capability.Layer && jsonCapabilities.Capability.Layer.Layer) {
          const layers = jsonCapabilities.Capability.Layer.Layer

          const layerInfo = layers.find((obj: any) => obj.Name === selectedLayer.name)
          if (layerInfo) {

            let extent;
            let transform;
            const geoBBOX = layerInfo.BoundingBox.find((obj: any) => obj.crs === 'CRS:84')
            if (geoBBOX) {
              extent = geoBBOX.extent;
              transform = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
            } else {
              const bbox = layerInfo.BoundingBox[0]
              if (bbox) {
                extent = transformExtent(bbox.extent, bbox.crs, 'EPSG:4326');
                transform = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
              } else {
                toast.current?.show({ severity: 'error', detail: "Katman BBOX'u Bulamıyorum", life: 5000 });
              }
            }
            dispatch(setExternalLayerExtent(transform))

            return { extent }
          } else {
            toast.current?.show({ severity: 'error', detail: 'Hizmette Katman Bulunamıyor', life: 5000 });
          }
        } else {
          toast.current?.show({ severity: 'error', detail: 'Hizmet Yetenekleri Ayrıştırılamıyor', life: 5000 });
        }
      } catch (error) {
        toast.current?.show({ severity: 'error', detail: 'Hizmet Yetenekleri Alınamıyor', life: 5000 });
        console.warn(error);
      }
    }
  }
  const goToTheRasterLayer = async (selectedLayer: any) => {
    if (findedService.type === 'RASTER') {
      try {
        const layerInfoResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}rest/v1/geoserver/rest/workspaces/${findedService.workspace}/coverages/${selectedLayer.name}`)
   
        const proj = layerInfoResponse.data.coverage.coverage.nativeBoundingBox.crs.$

        const CodeInfoProj = proj4List[proj]

        proj4.defs(CodeInfoProj)
      
        register(proj4);

        const extObj = layerInfoResponse.data.coverage.coverage.nativeBoundingBox

        const extent = transformExtent([extObj.minx, extObj.miny, extObj.maxx, extObj.maxy], proj, 'EPSG:4326');

        const transform = transformExtent(extent, 'EPSG:4326', 'EPSG:3857')

        transform[0] = transform[0] - 0.01
        transform[1] = transform[1] + 0.01
        transform[2] = transform[2] + 0.01
        transform[3] = transform[3] - 0.01


        dispatch(setExternalLayerExtent(transform))
      } catch (error: any) {
        console.log("Error", error)
        // toast.current?.show({ severity: 'error', detail: 'Katman Bilgisi Alınamadı', life: 3000 });
      }
    }
  }

  useEffect(() => {
    if (services && services.length > 0) {

      const promiseArray: any[] = [];
      const res: any = [];
      projects.map((project: Project) => {
        project.layer_groups.map((layerGroup: LayerGroup) => {
          layerGroup.layers.map((layer: Layer) => {
            const type = services.find((service: any) => service.id === layer.service_id)?.type
            if (type === 'RASTER' || type === 'EXTERNAL_LAYER') {
              return
            }
            else/* if(layer.visible)*/ {
              // console.log("layer :",layer);
              const response = LayerRestApi.getStyleWithLayername(layer.name);
              promiseArray.push(response)
            }
          })
        })
      })
      Promise.all(promiseArray).then((responseArray: any) => {
        if (responseArray) {
          responseArray.map((response: any) => {
            if (response) {
              res.push(...response.data.style)
            }
          })
          // const btnState = buttonState;
          // dispatch(setButtonState(ButtonState.NONE))
          // console.log("btnState : ",btnState);
          // if(buttonState === ButtonState.ADD || buttonState === ButtonState.EDIT || buttonState === ButtonState.INFO ||
          //   buttonState === ButtonState.DELETE || buttonState === ButtonState.SPLIT || buttonState === ButtonState.MOVE ||
          //   buttonState === ButtonState.PARALLEL || buttonState === ButtonState.HOLE || buttonState === ButtonState.SELECTION || buttonState === ButtonState.VERTEX){
          //     setTimeout(() => {
          //         console.log("confirmPopup : ",buttonState);
          //         dispatch(setButtonState(btnState))
          //     }, 0);
          // }
          setUsedStyles(res)
          /**
           * kontrol edilmeli
           */
          dispatch(setStyles(res))
        }
      })
      /**
      * kontrol edilmeli
      */
      // dispatch(setStyles(styles))
    }
  }, [/*projects,*/ services])


  useEffect(() => {
    if (services && services.length > 0) {
      const visibleLayers = parsedLayers.filter((el: any) => el.visible === true)
      const partials = parsedLayers.filter((el: any) => el.partial === true)
      const obj = { ...selectedKeys };
      for (const layer of visibleLayers) {
        obj[layer.name] = {
          checked: true,
          partialChecked: false,
        };
      }
     
      if(isMobileScreen){
        obj["panogps"] = {
          checked: true,
          partialChecked: false,
        };
        // console.log("obj:",obj);
      }
      partials.forEach((layer: any) => {
        if (layer.name.includes('/')) {
          obj[layer.name] = {
            checked: false,
            partialChecked: true,
          };
        } else {
          obj[layer.name] = {
            checked: false,
            partialChecked: true,
          };
        }

      });
      if (offlines.length) {
        offlines.forEach((offline: any) => {
          obj[offline.key] = {
            checked: true,
            partialChecked: false,
          }
        })
      }
      setSelectedKeys(obj)
      dispatch(setSelectedKeysforPano(obj))
    }

  }, [services, offlines]);

  useEffect(() => {
    if (!isExpandedKeysDefaultFlag) {
      const theArray = []
      const expandedKeysKeys = Object.keys(expandedKeys)
      if (expandedKeysKeys?.length) {
        for (let i = 0; i < expandedKeysKeys.length; i++) {
          const key = expandedKeysKeys[i];
          if (nodes?.length) {
            for (let j = 0; j < nodes.length; j++) {
              const node = nodes[j] as any;
              if (node.key == key) {
                theArray.push({ project_id: node.id })
              }
              const children = node.children
              if (children?.length) {
                for (let k = 0; k < node.children.length; k++) {
                  const child = node.children[k];
                  if (child.key == key) {
                    theArray.push({ project_id: node.id, layer_group_id: child.id })
                  }
                  const grandChildren = child.children
                  if (grandChildren.length) {
                    for (let l = 0; l < grandChildren.length; l++) {
                      const grandChild = grandChildren[l];
                      if (grandChild.key == key) {
                        theArray.push({ project_id: node.id, layer_group_id: child.id, layer_id: grandChild.id, layer_name: grandChild.name })
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      localStorage.setItem('expandedKeysFromLocal', JSON.stringify(theArray))

      if (Object.keys(expandedKeys).length > 0) {
        setCollapseButtonVis(true)
      }
      else {
        setCollapseButtonVis(false)
      }
    }
  }, [expandedKeys])


  function generatePointStyle(style: any, layerId: any, domId: any) {
    let dataURL = '';
/*     console.log("style :",style);
    console.log("style.is_icon :",style.is_icon); */
    dataURL = style.icon;
 /*    if (style.is_icon) {
        dataURL = style.icon;
    } else {
        const lineCanvas = document.createElement('canvas');
        const ctx = lineCanvas.getContext('2d')!;
        lineCanvas.width = 20;
        lineCanvas.height = 20;

        if (style.shape === 'circle') {
            ctx.beginPath();
            ctx.fillStyle = style.fill_color;
            ctx.arc(10, 10, 8, 0, 2 * Math.PI);
            ctx.fill();
        } else if (style.shape === 'triangle') {
            lineCanvas.width = 100;
            lineCanvas.height = 100;
            ctx.beginPath();
            ctx.moveTo(50, 0);
            ctx.lineTo(0, 100);
            ctx.lineTo(100, 100);
            ctx.closePath();
            ctx.fillStyle = style.fill_color;
            ctx.lineWidth = style.stroke_width;
            ctx.strokeStyle = style.stroke_color;
            ctx.fill();
        } else if (style.shape === 'square') {
            lineCanvas.width = 100;
            lineCanvas.height = 100;
            ctx.clearRect(0, 0, 100, 100);
            ctx.beginPath();
            ctx.lineWidth = style.stroke_width;
            ctx.strokeRect(0, 0, 100, 100);
            ctx.rect(0, 0, 100, 100);
            ctx.fillStyle = style.fill_color;
            ctx.strokeStyle = style.stroke_color;
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
        }

        dataURL = lineCanvas.toDataURL();
    } */

    return `\n.iconforsidebar_${layerId}${domId} { background-image: url(${dataURL}) }`;
}

function generateLineStringStyle(style: any, layerId: any, domId: any) {
/*     const lineCanvas = document.createElement('canvas');
    lineCanvas.width = 20;
    lineCanvas.height = 20;
    const ctx = lineCanvas.getContext('2d')!;
    ctx.moveTo(0, 10);
    ctx.lineTo(20, 10);
    ctx.lineWidth = style.stroke_width;
    ctx.strokeStyle = style.stroke_color;
    ctx.stroke();
    const dataURL = lineCanvas.toDataURL(); */

    const dataURL = style.icon;

    return `\n.iconforsidebar_${layerId}${domId} { background-image: url(${dataURL}) }`;
}

const poli = document.createElement('canvas');
poli.width = 100;
poli.height = 100;
const ctx = poli.getContext('2d')!;
ctx.fillStyle = "#ffffff";
ctx.strokeStyle = "#ffffff";
ctx.lineWidth = 2;
ctx.beginPath();
ctx.moveTo(50 + 50 * Math.cos(0), 50 + 50 * Math.sin(0));
for (let i = 1; i <= 5; i += 1) {
    ctx.lineTo(50 + 50 * Math.cos(i * 2 * Math.PI / 5), 50 + 50 * Math.sin(i * 2 * Math.PI / 5));
}
ctx.lineWidth = 6;
ctx.stroke();
ctx.closePath();
ctx.fill();
 
function generatePolygonStyle(style: any, layerId: any, domId: any) {
/*     const ctx = poli.getContext('2d')!;
    ctx.fillStyle = style.fill_color;
    ctx.strokeStyle = style.stroke_color;
    ctx.lineWidth = style.stroke_width;
    
    const dataURL = poli.toDataURL(); */
    const dataURL = style.icon;
    return `\n.iconforsidebar_${layerId}${domId} { background-image: url(${dataURL}) }`;
}

function generateUnassignedStyle(style: any) {
    if (/*style.is_icon */style.icon) {
        return style.icon;
    }

    let dataURL = '';
    const lineCanvas = document.createElement('canvas');
    const ctx = lineCanvas.getContext('2d')!;

    if (style.type === 'point') {
        lineCanvas.width = 20;
        lineCanvas.height = 20;

        if (style.shape === 'circle') {
            ctx.beginPath();
            ctx.fillStyle = style.fill_color;
            ctx.arc(10, 10, 8, 0, 2 * Math.PI);
            ctx.fill();
        } else if (style.shape === 'triangle') {
            lineCanvas.width = 100;
            lineCanvas.height = 100;
            ctx.beginPath();
            ctx.moveTo(50, 0);
            ctx.lineTo(0, 100);
            ctx.lineTo(100, 100);
            ctx.closePath();
            ctx.fillStyle = style.fill_color;
            ctx.lineWidth = style.stroke_width;
            ctx.strokeStyle = style.stroke_color;
            ctx.fill();
        } else if (style.shape === 'square') {
            lineCanvas.width = 100;
            lineCanvas.height = 100;
            ctx.clearRect(0, 0, 100, 100);
            ctx.beginPath();
            ctx.lineWidth = style.stroke_width;
            ctx.strokeRect(0, 0, 100, 100);
            ctx.rect(0, 0, 100, 100);
            ctx.fillStyle = style.fill_color;
            ctx.strokeStyle = style.stroke_color;
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
        }

        dataURL = lineCanvas.toDataURL();
    } else if (style.type === 'linestring') {
        lineCanvas.width = 20;
        lineCanvas.height = 20;
        ctx.moveTo(0, 10);
        ctx.lineTo(20, 10);
        ctx.lineWidth = style.stroke_width;
        ctx.strokeStyle = style.stroke_color;
        ctx.stroke();
        dataURL = lineCanvas.toDataURL();
    } else if (style.type === 'polygon') {
         
        const polygonCtx = poli.getContext('2d')!;
        polygonCtx.fillStyle = style.fill_color;
        polygonCtx.strokeStyle = style.stroke_color;
        polygonCtx.lineWidth = style.stroke_width;
        
        polygonCtx.fill();
        dataURL = poli.toDataURL();
    }

    return dataURL;
}

  useEffect(() => {
    let keyCount = sidebarKeyCount

    if (styles && styles.length > 0) {

      let data: any[] = [];
      let mainData: any[] = [];
      let childrenData: any[] = [];
      let domainData: any[] = [];
    //  const classes = document.createElement('style');
   //   classes.innerHTML = ``;

      // dispatch(setStyles(styles));
      for (const project of projects) {
        
      // projects.map((project: Project) => {
        mainData = []
        for (const layerGroup of project.layer_groups) {
          
        // }
        // project.layer_groups.map((layerGroup: LayerGroup) => {
          // if (layerGroup.layers.length > 0) { layerGroup.layers.length > 0
            childrenData = [];
            // layerGroup.layers.find((layer: any) => {
            for (const layer of layerGroup.layers as any[]) {
              
              const style = styles.find((style: any) => style.layer_id === layer.id);
              let domainLayers;
               if (style) {
                domainLayers = layer.fields.filter((field: Field) => field.type === 'Domain' && field.id === style.field_id);
                // console.log("domainLayers.length ",domainLayers.length);
                
                if (domainLayers.length > 0) {
                  for (let ii = 0; ii < domainLayers.length; ii++) {
                    // for ( const domainLayer of domainLayers) {
                            jj++;
                            const domainLayer :any = domainLayers[ii]

                            styleRef.push(new Map())
                            let newChild = {
                                ...layer,
                                key: layer.name,
                                label: layer.alias,
                                icon: `iconforsidebar_${layer.name}`
                            };
                            
                                const styleArray = styles.filter((style: any) => style.layer_id === layer.id);

                                mapOfStyleArray.set(layer.id, styleArray)

                                let fieldName: any;
                                let classUpdates = '';
                
                                styleArray.forEach((style: any, index: any) => {
                                    const newArr = domainLayer.domain ? domainLayer.domain.map((dom: any) => {
                                      if (style.value_id === dom.id) {
                                        // console.log("dom.id :",dom.id);
                                        styleRef[jj-1].set(dom.id, index)

                                        fieldName = layer.fields.find((field: Field) => field.id === style.field_id)?.name;

                                    /*     if(jj === 2){
                                          console.log("styleArray",styleArray);
                                          
                                          console.log("jj :",jj);

                                          console.log("domwww :wwwwwww w",domainLayer.domain);

                                          console.log("dom.id :",dom.id);
                                          console.log("styleRef[jj-1].get(dom.id) :",styleRef[jj-1].get(dom.id));
  
                                        } */
                                      }
                                    

                                        return { ...dom, key: `domain/${fieldName}/${dom.id}/${layer.name}`, label: dom.desc, icon: `iconforsidebar_${layer.id}${dom.id}` };
                                    }) : [];
                    
                                    if (index === styleArray.length - 1) {
                                        const unAssignedStyle = styleArray.find((el: any) => el.value_id === -1);
                    
                                        if (unAssignedStyle) {
                                            const dataURLShape = generateUnassignedStyle(unAssignedStyle);
                                            newArr.push({
                                                id: unAssignedStyle.value_id,
                                                desc: unAssignedStyle.value_desc,
                                                key: `domain/${fieldName}/-1/${layer.name}`,
                                                icon: `iconforsidebar_${layer.id}-1`,
                                                label: unAssignedStyle.value_desc
                                            });
                                            classUpdates += `\n.iconforsidebar_${layer.id}-1 { background-image: url(${dataURLShape}) }`;
                                        }
                                    }
                    
                                    newChild = {
                                        ...newChild,
                                        children: newArr
                                    };
                                });
                                // console.log("styleRef :",styleRef);
                                childrenData.push(newChild);
    
                                const str = document.createElement('style');
                                str.innerHTML = classUpdates
                                      
                                setTimeout(function() {
                                  documentHead.appendChild(str)
                                }, 0);
    
                             //   classes.innerHTML += classUpdates;
                            // }
                        // }
                      }
                    
                }
                else {
                  
                  // if (layer.layer_group_id === layerGroup.id) {
                    let newChild = {
                      ...layer,
                      key: layer.name,
                      label: layer.alias,
                      icon: `iconforsidebar_${layer.name}`
                    }
                    childrenData.push(newChild);
                  // }
                }

              }
              else {
                const type = services.find((service: any) => service.id === layer.service_id)?.type
                if (type === 'RASTER') {
                  const classPoint = `\n.iconforsidebar_${layer.name} { background-image: url(icons/raster.svg); min-width: 17px; }`
               //   classes.innerHTML += ` ${classPoint}`

                  const str = document.createElement('style');
                  str.innerHTML = classPoint
                             
                  setTimeout(function() {
                    documentHead.appendChild(str)
                  }, 0);


                  // if (layer.layer_group_id === layerGroup.id) {
                    let newChild = {
                      ...layer,
                      key: layer.name,
                      label: layer.alias,
                      icon: `iconforsidebar_${layer.name}`
                    }
                    childrenData.push(newChild);
                  // }
                }
                if (type === 'EXTERNAL_LAYER') {
                  switch (layer.geomtype) {
                    case 'MultiPointZ':
                      // console.log("style && style.icon :",style && style.icon);
                      
                      if (style && style.icon) {
                        //domainli layer değilse
                        if (/*layer.layer_group_id === layerGroup.id && */domainLayers?.length > 0) {
                          const canvas = document.createElement('canvas');
                          const ctx = canvas.getContext('2d')!;
                          canvas.width = 20
                          canvas.height = 20
                          ctx.beginPath();
                          ctx.fillStyle = '#ffffff'
                          ctx.arc(10, 10, 8, 0, 2 * Math.PI);
                          ctx.fill();
                          const dataURL = canvas.toDataURL();
                          const classPoint = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}); min-width: 17px; }`
                        //  classes.innerHTML += ` ${classPoint}`

                          const str = document.createElement('style');
                          str.innerHTML = `${classPoint}`
                                      
                          setTimeout(function() {
                            documentHead.appendChild(str)
                          }, 0);


                        }
                        //domainli layersa
                        else {
                          const str = document.createElement('style');
                          str.innerHTML = `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
                                     
                          setTimeout(function() {
                            documentHead.appendChild(str)
                          }, 0);

                       //   classes.innerHTML += `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`

                        }
                      }
                      else {
                        const lineCanvas = document.createElement('canvas');
                        const ctx = lineCanvas.getContext('2d')!;
                        // if (style.shape === 'circle') {
                        lineCanvas.width = 20
                        lineCanvas.height = 20
                        ctx.beginPath();
                        ctx.fillStyle = '#ffffff';
                        ctx.arc(10, 10, 8, 0, 2 * Math.PI);
                        ctx.fill();
                        const dataURL = lineCanvas.toDataURL();
                        const classPoint = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}); min-width: 17px; }`
                    //    classes.innerHTML += ` ${classPoint}`

                        const str = document.createElement('style');
                        str.innerHTML = classPoint
                                    
                        setTimeout(function() {
                          documentHead.appendChild(str)
                        }, 0);
                      }
                      break;
                    case 'MultiLineStringZ':
                      const lineCanvas = document.createElement('canvas');
                      const lineCtx = lineCanvas.getContext('2d')!;
                      lineCanvas.width = 20
                      lineCanvas.height = 20
                      lineCtx.moveTo(0, 10);
                      lineCtx.lineTo(20, 10);
                      lineCtx.lineWidth = 1
                      lineCtx.strokeStyle = '#ffffff'
                      lineCtx.stroke();
                      const dataURL = lineCanvas.toDataURL();
                      const classString = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}) }`
                  //    classes.innerHTML += ` ${classString}`

                      const str1 = document.createElement('style');
                      str1.innerHTML = classString
                                 
                      setTimeout(function() {
                        documentHead.appendChild(str1)
                      }, 0);

                      break;
                    case 'MultiPolygonZ':
                       
                      const polygonCtx = poli.getContext('2d')!;
                      polygonCtx.fillStyle = '#ffffff';
                      polygonCtx.strokeStyle = '#ffffff';
                      polygonCtx.lineWidth = 1;
                      
                      polygonCtx.fill();
                      const dataURLPolygon = poli.toDataURL();

                      const classStringPolygon = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURLPolygon}) }`
                  //    classes.innerHTML += ` ${classStringPolygon}`


                      const str2 = document.createElement('style');
                      str2.innerHTML = classStringPolygon
                                 
                      setTimeout(function() {
                        documentHead.appendChild(str2)
                      }, 0);

                      break;
                    default:
                      break;
                  }
                  // console.log("??",layer.layer_group_id === layerGroup.id);
                  
                  // if (layer.layer_group_id === layerGroup.id) {
                    let newChild = {
                      ...layer,
                      key: layer.name,
                      label: layer.alias,
                      icon: `iconforsidebar_${layer.name}`
                    }
                    childrenData.push(newChild);
                  // }
                }
              }
            }
            // })
            const sortedChildrenData = sortByPriority(childrenData);
            // console.log("??",childrenData);
            if (/*project.id === layerGroup.project_id &&*/ childrenData.length > 0) {
              const otherChild = {
                ...layerGroup,
                label: layerGroup.alias,
                key: keyCount,
                children: sortedChildrenData
              }
              keyCount = 'key.' + (parseInt(keyCount.split('.')[1]) + 1).toString()
              mainData.push(otherChild)
            }
          // } layerGroup.layers.length > 0
        }
        // })
        const sortedMainData = sortByPriority(mainData);

        if (mainData.length > 0) {
          const allData: any = {
            ...project,
            label: project.alias,
            key: keyCount,
            children: sortedMainData
          }
          keyCount = 'key.' + (parseInt(keyCount.split('.')[1]) + 1).toString()

          //? if (allData.layer_groups && allData.layer_groups.length > 0) {
            data.push(allData)
          //? }
        }

      }
      const sorted = sortByPriority(data);
      const sortedNodes = [...sorted, ...offlines]

      setNodes(sortedNodes)
      dispatch(setLayerNodes([...sortedNodes]));
      let _expandedKeys = {};
      for (let node of sortedNodes) {
          expandNode(node, _expandedKeys);
          if(node.children && node.children.length){
            for (let children of node.children) {
              expandNode(children, _expandedKeys);
            }
          }
      }
      setWasNotesSet(true)

      // setStyleRef(styleRef)
      // console.log("mapOfStyleArray :",mapOfStyleArray);
      
      // setMapOfStyleArray(mapOfStyleArray)

      doLoad(mapOfStyleArray, styleRef).then(() => {/*console.log("Task Complete!")*/});
  
    }

    setSidebarKeyCount(keyCount)
    
  }, [styles])

  useEffect(() => {
    if (importLayerGroup && nodes) {
      const isNotElement = nodes.find((el: any) => el.children[0].children[0].label === importLayerGroup.children[0].label);
      if (!isNotElement) {
        const offlineLayer: any = {
          id: 11111 + importedFile.length,
          name: "cevirimdisi",
          key: `Çevirimdışı - ${offlines.length + 1}`,
          label: `Çevirimdışı - ${offlines.length + 1}`,
          children: [{
            id: importLayerGroup.children[0].id,
            project_id: 1,
            name: 'Çevirimdışı',
            key: `Çevirimdışı - ${offlines.length + 1}`,
            label: `Çevirimdışı - ${offlines.length + 1}`,
            children: [{
              id: importLayerGroup.children[0].id,
              key: `Çevirimdışı - ${offlines.length + 1}`,
              label: importLayerGroup.children[0].label,
              name: "Çevirimdışı2",
              layer_type: "offline",
              features: undefined,
              vector: undefined
            }]
          }]
        }
        
        nodes.push(offlineLayer)
        dispatch(setOfflines([...offlines, offlineLayer]));
        setSelectedGroupAlias(null)
      }
    }
    setNodes(nodes)
  }, [importLayerGroup]);

  const doLoad = (mapOfStyleArray: any, styleRefTmp :any)=>{
    const seconds = sidebarVisibility ? 0 : 2000;
    const promise = new Promise((resolve, reject) => {
      
      setTimeout(() => {
        if(!(window as any).iconload){
          let keyCount = sidebarKeyCount

          jj=0;
          
          setWasNotesSet(false)
    
          if (styles && styles.length > 0) {
  
            let data: any[] = [];
            let mainData: any[] = [];
            let childrenData: any[] = [];
            let domainData: any[] = [];
      //      const classes = document.createElement('style');
      //      classes.innerHTML = ``;
            for (const project of projects) {
              
              mainData = []
              for (const layerGroup of project.layer_groups) {
                  childrenData = [];
                  for (const layer of layerGroup.layers as any[]) {
                    
                    let style = styles.find((style: any) => style.layer_id === layer.id);
                    let domainLayers;
                    // if (style) {
                      domainLayers = layer.fields.filter((field: Field) => field.type === 'Domain' && field.id === style.field_id);
                    // }
                    if (style) {
                      if (!style.icon?.includes('data:image')) {
                        if (style?.icon)
                        {
                          style = { ...style, icon: `data:image/${style.icon}` };
                        }
                        
                      }
                      switch (style.type) {
                        case 'point':
                    //      classes.innerHTML += `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
      
                          const str = document.createElement('style');
                          str.innerHTML = `\n.iconforsidebar_${layer.name} { background-image: url(${style.icon}) }`;
      
                          setTimeout(function() {
                            documentHead.appendChild(str)
                          }, 0);
      
                          break;
                        case 'linestring':
                   //       classes.innerHTML += `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
      
                          const str2 = document.createElement('style');
                          str2.innerHTML = `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
                          
                          setTimeout(function() {
                            documentHead.appendChild(str2)
                          }, 0);
                          break;
                        case 'polygon':
                   //       classes.innerHTML += `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
      
                          const str3= document.createElement('style');
                          str3.innerHTML = `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
                          
                          setTimeout(function() {
                            documentHead.appendChild(str3)
                          }, 0);
                      
                          break;
                        default:
                          break;
                      }
                      //domain icon için burdan devam
                      if (domainLayers.length > 0) {
                        
                        for (let ii = 0; ii < domainLayers.length; ii++) {
                          // for ( const domainLayer of domainLayers) {
                            jj++;

                              const domainLayer :any = domainLayers[ii]
                          
                              let newChild = {
                                  ...layer,
                                  key: layer.name,
                                  label: layer.alias,
                                  icon: `iconforsidebar_${layer.name}`
                              };
                              // console.log("??? ",layer.id === domainLayer.layer_id);
                              
                              // if (layer.id === domainLayer.layer_id) {
                                  // const styleArray = styles.filter((style: any) => style.layer_id === layer.id);


                                 // const styleArray =  mapOfStyles.get(layer.id)
                                 const styleArray =  mapOfStyleArray.get(layer.id)

                                  
                                  // console.log("!!! :",styleArray.filter((style:any)=> !style.icon));
      
                                  let fieldName: any;
                                  let classUpdates = '';
                                  // console.log("styleArray :len ",styleArray.length);
                  
                                  // styleArray.forEach((style: any, index: any) => {
                                      const newArr = domainLayer.domain ? domainLayer.domain.map((dom: any) => {
                                      // console.log("styleRef.get(dom.id) ",styleRef.get(dom.id));

                                    /* if(jj === 2){
                                  console.log("domainLayer.domain :",domainLayer.domain);
                            console.log("jj   ====",jj);
                                        
                                        console.log("dom.id ",dom.id);
                                        console.log("styleArray ",styleArray);  
                                        console.log("styleRef ",styleRef);  

                                        console.log("styleRef[jj-1].get(dom.id) :",styleRef[jj-1].get(dom.id));
                                      } */ 
                                      
                                
                                  /*     console.log("styleRefTmp :",styleRefTmp);
                                      console.log("styleArray :",styleArray); */
                                        
                                      const style :any = styleArray[styleRefTmp[jj-1].get(dom.id)]
                                     // const style :any = styleArray[styleRef[jj-1].get(dom.id)]
                                      if(style){
                                        classUpdates += `\n.iconforsidebar_${layer.id}${dom.id} { background-image: url(${style.icon}) }`
                                        fieldName = layer.fields.find((field: Field) => field.id === style.field_id)?.name;
      
                                      }
                                    
                                          // console.log("fieldName",fieldName);
                                          
                                          return { ...dom, key: `domain/${fieldName}/${dom.id}/${layer.name}`, label: dom.desc, icon: `iconforsidebar_${layer.id}${dom.id}` };
                                      }) : [];
                      
                                      // if (index === styleArray.length - 1) {
                                          const unAssignedStyle = styleArray.find((el: any) => el.value_id === -1);
                      
                                          if (unAssignedStyle) {
                                              const dataURLShape = generateUnassignedStyle(unAssignedStyle);
                                              newArr.push({
                                                  id: unAssignedStyle.value_id,
                                                  desc: unAssignedStyle.value_desc,
                                                  key: `domain/${fieldName}/-1/${layer.name}`,
                                                  icon: `iconforsidebar_${layer.id}-1`,
                                                  label: unAssignedStyle.value_desc
                                              });
                                              classUpdates += `\n.iconforsidebar_${layer.id}-1 { background-image: url(${dataURLShape}) }`;
                                          }
                                      // }
                      
                                      newChild = {
                                          ...newChild,
                                          children: newArr
                                      };
                                  // });
                      
                                  childrenData.push(newChild);
      
                                  const str = document.createElement('style');
                                  str.innerHTML = classUpdates
                                        
                                  setTimeout(function() {
                                    documentHead.appendChild(str)
                                  }, 0);
      
                              //    classes.innerHTML += classUpdates;
                              // }
                          // }
                        }
                      // });
                        
                      }
                      else {
                        
                        // if (layer.layer_group_id === layerGroup.id) {
                          let newChild = {
                            ...layer,
                            key: layer.name,
                            label: layer.alias,
                            icon: `iconforsidebar_${layer.name}`
                          }
                          childrenData.push(newChild);
                        // }
                      }
                    }
                    else {
                      const type = services.find((service: any) => service.id === layer.service_id)?.type
                      if (type === 'RASTER') {
                        const classPoint = `\n.iconforsidebar_${layer.name} { background-image: url(icons/raster.svg); min-width: 17px; }`
                //        classes.innerHTML += ` ${classPoint}`
      
                        const str = document.createElement('style');
                        str.innerHTML = classPoint
                                  
                        setTimeout(function() {
                          documentHead.appendChild(str)
                        }, 0);
      
      
                        // if (layer.layer_group_id === layerGroup.id) {
                          let newChild = {
                            ...layer,
                            key: layer.name,
                            label: layer.alias,
                            icon: `iconforsidebar_${layer.name}`
                          }
                          childrenData.push(newChild);
                        // }
                      }
                      if (type === 'EXTERNAL_LAYER') {
                        switch (layer.geomtype) {
                          case 'MultiPointZ':
                            // console.log("style && style.icon :",style && style.icon);
                            
                            if (style && style.icon) {
                              //domainli layer değilse
                              if (/*layer.layer_group_id === layerGroup.id && */domainLayers?.length > 0) {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d')!;
                                canvas.width = 20
                                canvas.height = 20
                                ctx.beginPath();
                                ctx.fillStyle = '#ffffff'
                                ctx.arc(10, 10, 8, 0, 2 * Math.PI);
                                ctx.fill();
                                const dataURL = canvas.toDataURL();
                                const classPoint = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}); min-width: 17px; }`
                         //       classes.innerHTML += ` ${classPoint}`
      
                                const str = document.createElement('style');
                                str.innerHTML = `${classPoint}`
                                            
                                setTimeout(function() {
                                  documentHead.appendChild(str)
                                }, 0);
      
      
                              }
                              //domainli layersa
                              else {
                                const str = document.createElement('style');
                                str.innerHTML = `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
                                          
                                setTimeout(function() {
                                  documentHead.appendChild(str)
                                }, 0);
      
                         //       classes.innerHTML += `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
      
                              }
                            }
                            else {
                              const lineCanvas = document.createElement('canvas');
                              const ctx = lineCanvas.getContext('2d')!;
                              // if (style.shape === 'circle') {
                              lineCanvas.width = 20
                              lineCanvas.height = 20
                              ctx.beginPath();
                              ctx.fillStyle = '#ffffff';
                              ctx.arc(10, 10, 8, 0, 2 * Math.PI);
                              ctx.fill();
                              const dataURL = lineCanvas.toDataURL();
                              const classPoint = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}); min-width: 17px; }`
                       //       classes.innerHTML += ` ${classPoint}`
      
                              const str = document.createElement('style');
                              str.innerHTML = classPoint
                                          
                              setTimeout(function() {
                                documentHead.appendChild(str)
                              }, 0);
                            }
                            break;
                          case 'MultiLineStringZ':
                            const lineCanvas = document.createElement('canvas');
                            const lineCtx = lineCanvas.getContext('2d')!;
                            lineCanvas.width = 20
                            lineCanvas.height = 20
                            lineCtx.moveTo(0, 10);
                            lineCtx.lineTo(20, 10);
                            lineCtx.lineWidth = 1
                            lineCtx.strokeStyle = '#ffffff'
                            lineCtx.stroke();
                            const dataURL = lineCanvas.toDataURL();
                            const classString = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}) }`
                     //       classes.innerHTML += ` ${classString}`
      
                            const str1 = document.createElement('style');
                            str1.innerHTML = classString
                                      
                            setTimeout(function() {
                              documentHead.appendChild(str1)
                            }, 0);
      
                            break;
                          case 'MultiPolygonZ':
                            
                            const polygonCtx = poli.getContext('2d')!;
                            polygonCtx.fillStyle = '#ffffff';
                            polygonCtx.strokeStyle = '#ffffff';
                            polygonCtx.lineWidth = 1;
                            
                            polygonCtx.fill();
                            const dataURLPolygon = poli.toDataURL();
      
                            const classStringPolygon = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURLPolygon}) }`
                     //       classes.innerHTML += ` ${classStringPolygon}`
      
      
                            const str2 = document.createElement('style');
                            str2.innerHTML = classStringPolygon
                                      
                            setTimeout(function() {
                              documentHead.appendChild(str2)
                            }, 0);
      
                            break;
                          default:
                            break;
                        }
                        // console.log("??",layer.layer_group_id === layerGroup.id);
                        
                        // if (layer.layer_group_id === layerGroup.id) {
                          let newChild = {
                            ...layer,
                            key: layer.name,
                            label: layer.alias,
                            icon: `iconforsidebar_${layer.name}`
                          }
                          childrenData.push(newChild);
                        // }
                      }
                    }
                  }
                  // })
                  const sortedChildrenData = sortByPriority(childrenData);
                  // console.log("??",childrenData);
                  if (/*project.id === layerGroup.project_id &&*/ childrenData.length > 0) {
                    const otherChild = {
                      ...layerGroup,
                      label: layerGroup.alias,
                      key: keyCount,
                      children: sortedChildrenData
                    }
                    keyCount = 'key.' + (parseInt(keyCount.split('.')[1]) + 1).toString()
                    mainData.push(otherChild)
                  }
              }
              const sortedMainData = sortByPriority(mainData);
      
              if (mainData.length > 0) {
                const allData: any = {
                  ...project,
                  label: project.alias,
                  key: keyCount,
                  children: sortedMainData
                }
                keyCount = 'key.' + (parseInt(keyCount.split('.')[1]) + 1).toString()
      
                //? if (allData.layer_groups && allData.layer_groups.length > 0) {
                  data.push(allData)
                //? }
              }
      
            }
            // })
            
            // documentHead.appendChild(classes);
            const sorted = sortByPriority(data);
            const sortedNodes = [...sorted, ...offlines]
      
            /* if (importLayerGroup) {
              const isNotElement = sortedNodes.find((el: any) => el.children[0].children[0].label === importLayerGroup.children[0].label);
              if (!isNotElement) {
                const offlineLayer: any = {
                  id: 11111 + importedFile.length,
                  name: "cevirimdisi",
                  key: `Çevirimdışı - ${offlines.length + 1}`,
                  label: `Çevirimdışı - ${offlines.length + 1}`,
                  children: [{
                    id: importLayerGroup.children[0].id,
                    project_id: 1,
                    name: 'Çevirimdışı',
                    key: `Çevirimdışı - ${offlines.length + 1}`,
                    label: `Çevirimdışı - ${offlines.length + 1}`,
                    children: [{
                      id: importLayerGroup.children[0].id,
                      key: `Çevirimdışı - ${offlines.length + 1}`,
                      label: importLayerGroup.children[0].label,
                      name: "Çevirimdışı2",
                      layer_type: "offline",
                      features: undefined,
                      vector: undefined
                    }]
                  }]
                }
                
                sortedNodes.push(offlineLayer)
                dispatch(setOfflines([...offlines, offlineLayer]));
                setSelectedGroupAlias(null)
              }
            }
            setNodes(sortedNodes) */
            // dispatch(setLayerNodes([...sortedNodes]));
      
            setWasNotesSet(true)
          }
      
          let arrayFromLocal = []
          const localString = localStorage.getItem('expandedKeysFromLocal')
          if (localString && localString != '') {
            arrayFromLocal = JSON.parse(localString)
          }
          setExpandedKeysArrayFromLocal(arrayFromLocal)
      
          setSidebarKeyCount(keyCount);
          
          (window as any).iconload = true;
          resolve(true);
        }
      }, seconds);
    });
    return promise;
  }

  useEffect(() => {
    if (!selectedKeys) {
      return
    }
    const newArray = setLayerVisibility()
    dispatch(setProjects([...newArray]))
    findSelectedDomains()

    if (selectedKeys) {
      const cevrimdisiKeys = Object.keys(selectedKeys).filter(
        (key: any) => selectedGroupAlias === key
      );
      if (cevrimdisiKeys.length > 0) {
        for (const key of cevrimdisiKeys) {
          if (key) {
            dispatch(setImportedFileVisibility(true));
          } else {
            dispatch(setImportedFileVisibility(false));
          }
        }
      } else if (/* Ayrıcaaaa.. */ selectedGroupAlias /* !null */) {
        dispatch(setImportedFileVisibility(false));
      }
    }
  }, [selectedKeys])

  const findSelectedDomains = () => {
    const visibleDomains: any = []
    const domainArr:any[] = Object.keys(selectedKeys).filter((key:any)=> key.includes('domain'));
    for (const key of domainArr) {
      const arr = key.split('/')
      visibleDomains.push({ filterTag: arr[1], domainId: arr[2], layername: arr[3] })
    }
    // for (const [key] of Object.entries(selectedKeys)) {
    //   if (key.includes('domain')) {
       
    //   }
    // }
    // console.log("visibleDomains :",visibleDomains);
    
    dispatch(setVisibleDomains(visibleDomains))
  }

  const setLayerVisibility = () => {
    dispatch(setIsLayerVisible(false))

    const sNodes: any[] = Object.keys(selectedKeys)
    
   /*  const sNode1s: any[] = [];
    for (const [key] of Object.entries(selectedKeys)) {
      sNode1s.push(key);
    } 
    console.log("sNode1s :",sNode1s);*/

    const copyProjects = [...projects]
    const newArray = copyProjects.map((project: Project) => {
      const layerGroups = project.layer_groups.map((layerGroup: LayerGroup) => {
        const layers = layerGroup.layers.map((layer: any) => {
          for (const field of layer.fields) {
            
          // }
          // layer.fields.map((field: any) => {
            if (field.has_domain) {
              field.domain?.forEach((domain: any, index: any) => {
                const domainKeyIndex = sNodes.findIndex((node: any) => node.includes(`domain/${field.name}/${domain.id}/${layer.name}`))
               
                 if (domainKeyIndex !== -1) {
                  
                  const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === `domain/${field.name}/${domain.id}/${layer.name}`);

                  if (findLayerIndex !== -1) {
                    if (selectedKeys[sNodes[domainKeyIndex]].checked === false) {
                      parsedLayers[findLayerIndex] = { name: `domain/${field.name}/${domain.id}/${layer.name}`, visible: false, partial: true };
                    } else {
                      const a = `domain/${field.name}/${domain.id}/${layer.name}`
                      parsedLayers[findLayerIndex] = { name: a, visible: true, partial: false };
                    }
                  } else {
                    if (selectedKeys[sNodes[domainKeyIndex]].checked === false) {
                      parsedLayers.push({ name: `domain/${field.name}/${domain.id}/${layer.name}`, visible: false, partial: true });
                    } else {
                      parsedLayers.push({ name: `domain/${field.name}/${domain.id}/${layer.name}`, visible: true, partial: false });
                    }
                  }

                  if(index === field.domain.length-1){
                    const domainKeyIndexUnassigned = sNodes.findIndex((node: any) => node.includes(`domain/${field.name}/-1/${layer.name}`))
                    if (domainKeyIndexUnassigned !== -1) {
                      const findLayerIndexUnassigned = parsedLayers.findIndex((el: any) => el.name === `domain/${field.name}/-1/${layer.name}`);
                      
                      if (findLayerIndexUnassigned !== -1) {
                        if (selectedKeys[sNodes[domainKeyIndexUnassigned]].checked === false) {
                          parsedLayers[findLayerIndexUnassigned] = { name: `domain/${field.name}/-1/${layer.name}`, visible: false, partial: true };
                        } else {
                          const a = `domain/${field.name}/-1/${layer.name}`
                          parsedLayers[findLayerIndexUnassigned] = { name: a, visible: true, partial: false };
                        }
                      } else {
                        if (selectedKeys[sNodes[domainKeyIndexUnassigned]].checked === false) {
                          parsedLayers.push({ name: `domain/${field.name}/-1/${layer.name}`, visible: false, partial: true });
                        } else {
                          parsedLayers.push({ name: `domain/${field.name}/-1/${layer.name}`, visible: true, partial: false });
                        }
                      }
                    }else{
                
                      const domainKeyIndexUnassigned = parsedLayers.findIndex((el: any) => el.name === `domain/${field.name}/-1/${layer.name}`);
                   
                      if (domainKeyIndexUnassigned !== -1) {
                        parsedLayers[domainKeyIndexUnassigned] = { name: `domain/${field.name}/-1/${layer.name}`, visible: false, partial: false };
                      } else {
                        parsedLayers.push({ name: `domain/${field.name}/-1/${layer.name}`, visible: false, partial: false });
                      }
                    }
                  }
 

                  return {
                    ...domain,
                    visible: true
                  }
                } else {
                  const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === `domain/${field.name}/${domain.id}/${layer.name}`);
                  if (findLayerIndex !== -1) {
                    parsedLayers[findLayerIndex] = { name: `domain/${field.name}/${domain.id}/${layer.name}`, visible: false, partial: false };
                  } else {
                    parsedLayers.push({ name: `domain/${field.name}/${domain.id}/${layer.name}`, visible: false, partial: false });
                  }
                  
                  return {
                    ...domain,
                    visible: false
                  }
                }


              })
             
            }
          }
          // })

          



          const layerKeyIndex = sNodes.findIndex((node: any) => node === layer.name)
          if (layerKeyIndex !== -1) {
            dispatch(setIsLayerVisible(true))

            const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === layer.name);
            if (findLayerIndex !== -1) {
              if (selectedKeys[sNodes[layerKeyIndex]].checked === false) {
                parsedLayers[findLayerIndex] = { name: layer.name, visible: false, partial: true };
              } else {
                parsedLayers[findLayerIndex] = { name: layer.name, visible: true, partial: false };
              }
            } else {
              if (selectedKeys[sNodes[layerKeyIndex]].checked === false) {
                parsedLayers.push({ name: layer.name, visible: false, partial: true });
              } else {
                parsedLayers.push({ name: layer.name, visible: true, partial: false });
              }
            }
             

            return {
              ...layer,
              visible: true
            }
          } else {
            const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === layer.name);
            if (findLayerIndex !== -1) {
              parsedLayers[findLayerIndex] = { name: layer.name, visible: false, partial: false };
            } else {
              parsedLayers.push({ name: layer.name, visible: false, partial: false });
            }
            

            return {
              ...layer,
              visible: false
            }
          }
        })

        const groupKeyIndex = sNodes.findIndex((node: any) => node === layerGroup.name)
        if (groupKeyIndex !== -1) {
          const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === layerGroup.name);
          if (findLayerIndex !== -1) {
            if (selectedKeys[sNodes[groupKeyIndex]].checked === false) {
              parsedLayers[findLayerIndex] = { name: layerGroup.name, visible: false, partial: true };
            } else {
              parsedLayers[findLayerIndex] = { name: layerGroup.name, visible: true, partial: false };
            }
          } else {
            if (selectedKeys[sNodes[groupKeyIndex]].checked === false) {
              parsedLayers.push({ name: layerGroup.name, visible: false, partial: true });
            } else {
              parsedLayers.push({ name: layerGroup.name, visible: true, partial: false });
            }
          }

        } else {

          const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === layerGroup.name);
          if (findLayerIndex !== -1) {
            parsedLayers[findLayerIndex] = { name: layerGroup.name, visible: false, partial: false };
          } else {
            parsedLayers.push({ name: layerGroup.name, visible: false, partial: false });
          }
        }
        

        

        return {
          ...layerGroup,
          layers: layers
        }
      })

      const projectKeyIndex = sNodes.findIndex((node: any) => node === project.name)
      if (projectKeyIndex !== -1) {
        const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === project.name);
        if (findLayerIndex !== -1) {
          if (selectedKeys[sNodes[projectKeyIndex]].checked === false) {
            parsedLayers[findLayerIndex] = { name: project.name, visible: false, partial: true };
          } else {
            parsedLayers[findLayerIndex] = { name: project.name, visible: true, partial: false };
          }
        } else {
          if (selectedKeys[sNodes[projectKeyIndex]].checked === false) {
            parsedLayers.push({ name: project.name, visible: false, partial: true });
          } else {
            parsedLayers.push({ name: project.name, visible: true, partial: false });
          }
        }
      } else {
        const findLayerIndex = parsedLayers.findIndex((el: any) => el.name === project.name);
        if (findLayerIndex !== -1) {
          parsedLayers[findLayerIndex] = { name: project.name, visible: false, partial: false };
        } else {
          parsedLayers.push({ name: project.name, visible: false, partial: false });
        }
      }
       

      return {
        ...project,
        layer_groups: layerGroups
      }
    })

      window.localStorage.setItem('sidebarLayersAreOpened', JSON.stringify(parsedLayers));
    return newArray;
  }

  const spanClicked = (e: any,node:any) => {
    const clickedNodeId = node.id;
    nodes?.map((node: any) => {
      if (node.children) {
        node.children.map((child: any) => {
          child.children.find((child2: any) => {
            if (child2.id === clickedNodeId) {
              if (child2 && child2.layer_type !== "offline") {
                const findLayerType = services.find((service: any) => service.id === child2.service_id).type
                const url = services.find((service: any) => service.id === child2.service_id)?.url
                
                const workspace = services.find((service: any) => service.id === child2.service_id).workspace;
                const service = services.find((service: any) => service.id === child2.service_id)
                const layerPerm = service?.layers.find((layer: any) => child2.id === layer.id)?.perm
                //  child2.perm = layerPerm
                const cloneObject = { ...child2 }//JSON.parse(JSON.stringify(child2));
                cloneObject.perm = layerPerm

                if (findLayerType !== 'EXTERNAL_LAYER' && findLayerType !== 'RASTER') {
                  dispatch(setSelectedLayerGeoserverUrl(url));
                  dispatch(setSelectedLayerGeoserverUrlTable(url));
                  dispatch(setWorkspace(workspace));
                  dispatch(setSelectedLayer(cloneObject))
                  dispatch(setSelectedLayerTable(cloneObject))
                  dispatch(setExportLayerAlias(cloneObject.alias))
                  dispatch(setExportLayerUrl(url))
                  dispatch(setExportLayerWorkspace(workspace))
                  setClickedId(clickedNodeId)

                } else return
              }
            }
          })
        })
      }
    })
    const sortedNodes = sortByPriority(nodes);
    setNodes(sortedNodes)
    if(e){
      e.stopPropagation();
    }
  }

  const sortByPriority = (array: any) => {
    const sortedArray = array.sort((a: any, b: any) => b.priority - a.priority);
    return sortedArray;
  }

  const getFeatures = async (url: any) => {
    const response = await FeatureRestApi.getFeatures(url, contextMenuSelectedItem.name)
    if (response) {
      dispatch(setFeatureExtent(response.data.bbox));
    }
  }

  const nodeTemplate = (node: any, options: any) => {
    let label = <b>{node.label}</b>;
    return (
      <div onClick={(e) => spanClicked(e, node)} className={`${selectedLayer.id && node.layer_group_id && node.id === clickedId ? 'selected-line' : 'line'} ${!node.layer_group_id ? '' : ''} ${node.layers ? 'boldGroup' : ''} `}>
        <div className={`label ${node.layer_groups && node.layer_groups.length ? 'boldClass' : ''}`}>{label}</div>
      </div>
    )
  }

  const expandNode = (node:any, _expandedKeys:any) => {
    if (node.children && node.children.length) {
        _expandedKeys[node.key] = true;
    }
  }
  const onToggle = (e: any) => {
    setExpandedKeys(e.value)
  }
  const toolTipText = t('BUTTON.Collapse All')
  return (
    <div className='Sidebar non-printable' ref={sidebarRef}>
        <ContextMenu model={contextMenuItems} ref={contextMenu} />
      <div className='collapse-button' >
        {collapseButtonVis && <Button tooltip={toolTipText} icon={<img src="/icons/collapse.svg" alt="Collapse Icon"></img>} onClick={() => { setExpandedKeys({}) }}></Button>}
      </div>
      <Tree
        filter
        filterPlaceholder = {language === 'tr' ? 'Katman filtrele' : 'Filter layers'}
        filterValue='as'
        value={nodes}
        expandedKeys={expandedKeys}
        nodeTemplate={nodeTemplate}
        onToggle={onToggle}
        selectionKeys={selectedKeys}
        selectionMode='checkbox'
        contextMenuSelectionKey={selectedNodeKey}
        onContextMenuSelectionChange={(e) => setSelectedNodeKey(e.value)}
        onContextMenu={(e:any) => {
          // contextMenu.current!.hide(e.originalEvent)
          projects.forEach((project: Project) => {
            project.layer_groups.forEach((layerGroup: LayerGroup) => {
              const finded = layerGroup.layers.find((layer: any) => layer.id === e.node.id)
              if (finded) {
                setContextMenuSelectedItem(finded);
                contextMenu.current!.layer = e.node.data;
                contextMenu.current!.show(e.originalEvent)
              }
            })
          })
        }}
        onSelectionChange={e => {
          setSelectedKeys(e.value)
          dispatch(setSelectedKeysforPano(e.value))
        }}
        onNodeClick={(e: any) => {
          setSelectedGroupAlias(e.node.key)
          dispatch(setEnabledGroupAlias(e.node.key))
          e.originalEvent.preventDefault();
        }}
      />
      <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />
    </div>
  )

}