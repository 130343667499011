import './App.scss';
import { Home } from './components/home/Home';
import { BrowserRouter as Router, Routes, Route, HashRouter, BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { Login } from './components/login/Login';
import { useState, useRef } from 'react';
import { useAppSelector } from './state/hooks';
import { useEffect } from 'react';
import { setLogOff } from './state/features/loginSlice';
import { useDispatch } from 'react-redux';
import { setIsMobileScreen } from './state/features/styleControlSlice';
import { CustomizeRestApi } from './util/restapi/customize';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import { Toast } from "primereact/toast";
import { setToastData } from "./state/features/toastSlice";
import { useTranslation } from 'react-i18next';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const loginStatus = useAppSelector(state => state.login.loginStatus)
  const toastData = useAppSelector((state) => state.toast.toastData)
  const toastRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getCustomize()
    if(+(window as any).innerWidth <= 640){
      dispatch(setIsMobileScreen(true))
    }else{
      dispatch(setIsMobileScreen(false))
    }
    dispatch(setLogOff(true)); 
    
  }, []);

  
  const getCustomize = async () => {
    try {
      const response :any = await CustomizeRestApi.customize()
      const favicon:any = document.getElementById('favicon');
      if (favicon) {
        favicon.href = response.data.customize.logo;
      }
    } catch (error) {
    }
  }


  useEffect(() => {
        
    if (toastRef.current && toastData.show) {
        toastRef.current.show({ severity: toastData.severity, summary: toastData.summary, detail: toastData.detail, life: toastData.life });

        dispatch(setToastData({
            severity: 'success',
            summary: '',
            detail: '',
            life: 5000,
            show: false
        }))
    }
}, [toastData])


  return (
    <HashRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        {/* do not change page route */}
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/*" element={<Home />} />
      </Routes>
      <Toast ref={toastRef} position="bottom-center" style={{ zIndex: '9 !important' }}></Toast>
    </HashRouter>
  );
}

export default App;
