import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env;
const SSE_SERVER_URL = 'http://localhost:3001';

interface RoadProfileParams {
    lon1: number;
    lat1: number;
    altitude1: number;
    lon2: number;
    lat2: number;
    altitude2: number;
    radius: number;
    timestamp: string;
}

interface RoadProfilePoint {
    dist: number;
    z: number;
    gpstime: string;
}

// Keep existing REST endpoint
async function getRoadProfile(lon1: number, lat1: number, altitude1: number, lon2: number, lat2: number, altitude2: number, radius: number, timestamp: string) {
    return axios.get(
        `${REACT_APP_BASE_URL}pano/pc/profile/0/${lon1}/${lat1}/${altitude1}/${lon2}/${lat2}/${altitude2}/${radius}/${timestamp}`
    );
}

// Add new SSE endpoint
function getRoadProfileStream(lon1: number, lat1: number, altitude1: number, lon2: number, lat2: number, altitude2: number, radius: number, timestamp: string) {
    const eventSource = new EventSource(
        `${SSE_SERVER_URL}/profile/0/${lon1}/${lat1}/${altitude1}/${lon2}/${lat2}/${altitude2}/${radius}/${timestamp}`
    );
    return eventSource;
}

export const RoadProfileRestApi = {
    getRoadProfile,
    getRoadProfileStream
};
