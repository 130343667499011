import { useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { DrawPoint } from './DrawPoint';
import { useAppSelector } from '../../../../../state/hooks';
import { DrawLinestring } from './DrawLinestring';
import { ClearAll } from './ClearAll';
import { DrawPolygon } from './DrawPolygon';
import { DrawStateType } from '../../../../../util/model/drawState';
import GeoJSON from 'ol/format/GeoJSON';
import CircleStyle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import { Snap } from 'ol/interaction';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import Overlay from 'ol/Overlay';
import { RadiusPopup } from '../../../panorama-container/road-profile/RadiusPopup';
import { RoadProfile } from '../../../panorama-container/road-profile/RoadProfile';
import { setLon1,setLon2,setLat1,setLat2,setAltitude1,setAltitude2, setTimestamp, setIsMap } from '../../../../../state/features/roadProfileDataSlice';
import { RoadProfileRestApi } from '../../../../../util/restapi/roadProfile';
import { AxiosError } from "axios";
import { setShowLoading } from '../../../../../state/features/loadingSlice';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';


interface ProfilePoint {
  x: number;
  y: number;
}


export const Draw = () => {
  
  const { REACT_APP_BASE_URL } = process.env;
  const toastSuccess = useRef<any>();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const map = useContext(MapContext);
  const drawState = useAppSelector(state => state.drawState.value);
  const panoramaCoordinates = useAppSelector((state) => state.coordinates.panoCoordinates);
  const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)
  const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
  const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
  const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
  const overlays = useRef<Overlay[]>([]);
  const [showRadiusPopup, setShowRadiusPopup] = useState(false);
  const [currentFeature, setCurrentFeature] = useState<any>(null);
  const [showRoadProfile , setShowRoadProfile] = useState(false);
  const [IsSubmit , setSubmit] = useState(false);
  const dispatch = useDispatch();
  const lon1 = useAppSelector((state) => state.roadProfileData.lon1);
  const lon2 = useAppSelector((state) => state.roadProfileData.lon2);
  const lat1 = useAppSelector((state) => state.roadProfileData.lat1);
  const lat2 = useAppSelector((state) => state.roadProfileData.lat2);
  const altitude1 = useAppSelector((state) => state.roadProfileData.altitude1);
  const altitude2 = useAppSelector((state) => state.roadProfileData.altitude2);
  const radius = useAppSelector((state) => state.roadProfileData.radius);
  const timestamp = useAppSelector((state) => state.roadProfileData.timestamp);
  const [profileData, setProfileData] = useState<ProfilePoint[]>([]);
  const isMap = useAppSelector(state => state.roadProfileData.isMap);






  const fetchRoadProfileData = () => {

      dispatch(setShowLoading(true));
      RoadProfileRestApi.getRoadProfile(lon1, lat1, altitude1, lon2, lat2, altitude2, radius, timestamp)
      .then((res) => {
        if (res.data.success) {
            const transformedData = res.data.points.map((point:any)=> ({
                x: point.dist,
                y: point.z
            }));
            dispatch(setShowLoading(false));
            setProfileData(transformedData);
            console.log("Data from Map Side",transformedData)
        }
    }).catch((error: AxiosError) => {
      toast.current?.show({ severity: 'info ', detail: t('MESSAGE.there is an error '), life: 3000 });
    })
 
};







if(IsSubmit){

  fetchRoadProfileData();
  setSubmit(false);
  dispatch(setButtonState(ButtonState.ROAD_PROFILE));
  setShowRoadProfile(true);

}

useEffect(() => {
  return () => {
      setProfileData([]);
      setShowRoadProfile(false);
      dispatch(setButtonState(ButtonState.NONE));
  };
}, []);


const handleRadiusSubmit = (radius: number) => {
  if (currentFeature) {
      setProfileData([]); // Clear previous data
      currentFeature.setProperties({ radius: radius });
      setShowRoadProfile(true);
  }
  setShowRadiusPopup(false);
};

 

  const handleDrawEnd = (feature: any) => {
    setCurrentFeature(feature);
    if(buttonState === ButtonState.ROAD_PROFILE){
      setShowRadiusPopup(true);
    }else{
      return;
    }
  };

  

  useEffect(() => {
    const source = new VectorSource({ wrapX: false });
    const vector = new VectorLayer({
      source: source,
      zIndex: 100000,
      style: new Style({
        fill: new Fill({
          color: '#64b5f640',
        }),
        stroke: new Stroke({
          color: '#3ea1f1',
          width: 3,
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: '#3ea1f1',
          }),
        }),
      }),
      properties:{
        alias: 'draws'
      }
    })

    const snap = new Snap({
      source: source
    })
    map.addInteraction(snap)

    map.addLayer(vector);
    setVector(vector)
    setSource(source)
    return () => {
      map.removeLayer(vector)
    }
  }, [])

  useEffect(() => {

    if (panoramaCoordinates.type) {
      const feature: any = new GeoJSON().readFeatures(panoramaCoordinates.feature.features[0]);
      if(buttonState === ButtonState.ADD){
        feature[0].setProperties({'alias': 'draws'})
      }
      source.addFeature(feature[0])
      setTextAfterDrawEnd(feature[0], panoramaCoordinates.text)
    }
  }, [panoramaCoordinates])

  const setTextAfterDrawEnd = (feature: any, text: string) => {
    feature.setStyle(new Style({
      fill: new Fill({
        color: '#64b5f640',
      }),
      stroke: new Stroke({
        color: '#3ea1f1',
        width: 3,
      }),
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: '#3ea1f1',
        }),
      }),
      text: new Text({
        text: `${text}`,
        font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
        placement: 'point',
        offsetY: -15,
        fill: new Fill({
          color: 'white',
        }),
        stroke: new Stroke({
          color: 'black',
          width: 3,
        }),
      }),
    }));
  }

  useEffect(() => {
    if (panoramaTransactStatus) {
      // console.log("source.",source);
     /*  if(buttonState !== ButtonState.MOVE || buttonState !== ButtonState.VERTEX){
        const ffs: any = source.getFeatures();
        source.removeFeature(ffs[ffs.length-1]);
        // source.clear();
      } */
      const layer_:any = map.getLayers().getArray().find((el:any)=> el.getProperties().alias === 'draws')
      const draws:any = [...layer_.getSource().getFeatures().filter((el:any)=>el.get('alias') !== 'draws')]
      if(draws.length){
          source.clear();
          source.addFeatures(draws)
      }else{
          source.clear();
      }
    }

    if (!showRoadProfile) {
      setProfileData([]);
  }

  }, [panoramaTransactStatus, showRoadProfile])


  useEffect(() => {
    
    if(lon1 == null){
        setProfileData([]);
    }

  },[buttonState,RadiusPopup,lon1,lat1,altitude1,lon2,lat2,altitude2,timestamp])


  return (
    <>
      {drawState.drawStateType === DrawStateType.POINT && <DrawPoint source={source} layer={vector}></DrawPoint>}
      {drawState.drawStateType === DrawStateType.LINESTRING && <DrawLinestring source={source}></DrawLinestring>}
      {drawState.drawStateType === DrawStateType.POLYGON && <DrawPolygon source={source} overlays={overlays}></DrawPolygon>}
      {drawState.drawStateType === DrawStateType.CLEAR && <ClearAll source={source} overlays={overlays} map={map} ></ClearAll>}

      {buttonState === ButtonState.ROAD_PROFILE && 
           <DrawLinestring source={source}
           onDrawComplete= {handleDrawEnd}
           />
      }
           
           <RadiusPopup
            visible={showRadiusPopup}
            onHide={() => setShowRadiusPopup(false)}
            onSubmit={(radius) => {
              handleRadiusSubmit(radius);
              setSubmit(true);
            }}
            />
      
      {showRoadProfile && profileData.length > 0 && buttonState === ButtonState.NONE && showRadiusPopup &&
       <RoadProfile data={profileData} />
      }

      {<Toast ref={toastSuccess} position='bottom-center' className='login-toast' style={{ zIndex: '9 !important' }} />}


    </>
  )
}