import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoadProfileDataState {
    lon1: number;
    lon2: number;
    lat1: number;
    lat2: number;
    altitude1: number;
    altitude2: number;
    radius: number;
    timestamp: string;
    profileGeoms: any; 
    panogl: any | null;
    isMap: boolean;


}

const initialState: RoadProfileDataState = {
    lon1: 0,
    lon2: 0,
    lat1: 0,
    lat2: 0,
    altitude1: 0,
    altitude2: 0,
    radius: 0,
    timestamp: '',
    profileGeoms: null,
    panogl: null,
    isMap: false

};

export const roadProfileDataSlice = createSlice({
    name: 'roadProfileData',
    initialState,
    reducers: {
        setLon1: (state, action: PayloadAction<number>) => {
            state.lon1 = action.payload;
        },
        setLon2: (state, action: PayloadAction<number>) => {
            state.lon2 = action.payload;
        },
        setLat1: (state, action: PayloadAction<number>) => {
            state.lat1 = action.payload;
        },
        setLat2: (state, action: PayloadAction<number>) => {
            state.lat2 = action.payload;
        },
        setAltitude1: (state, action: PayloadAction<number>) => {
            state.altitude1 = action.payload;
        },
        setAltitude2: (state, action: PayloadAction<number>) => {
            state.altitude2 = action.payload;
        },
        setRadius: (state, action: PayloadAction<number>) => {
            state.radius = action.payload;
        },
        setTimestamp: (state, action: PayloadAction<string>) => {
            state.timestamp = action.payload;
        },
        resetRoadProfileData: (state) => {
            return initialState;
        },
        setProfileGeoms: (state, action: PayloadAction<any>) => {
            state.profileGeoms = action.payload;
        },
        setPanogl: (state, action: PayloadAction<any>) => {
            state.panogl = action.payload;
        },
        setIsMap: (state, action: PayloadAction<boolean>) => {
            state.isMap = action.payload;
        }
    }
});

export const {
    setLon1,
    setLon2,
    setLat1,
    setLat2,
    setAltitude1,
    setAltitude2,
    setRadius,
    setTimestamp,
    resetRoadProfileData,
    setProfileGeoms,
    setPanogl,
    setIsMap
} = roadProfileDataSlice.actions;

export default roadProfileDataSlice.reducer;
