import { createSlice } from '@reduxjs/toolkit';
interface IInitialState {
    toastData: {
        severity: string,
        summary: string,
        detail: string,
        life: number,
        show: boolean
    },
}

const initialState: IInitialState = {
    toastData: {
        severity: 'success',
        summary: '',
        detail: '',
        life: 5000,
        show: false
    }
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setToastData: (state: IInitialState, action) => {
            state.toastData = action.payload;
        },
    }
})

export const { setToastData } = toastSlice.actions

export default toastSlice.reducer